@media screen and (min-width: 1200px) and (orientation: landscape) {
	.blog-post-note {
		font-size: 20px;
	}
}

@media screen and (min-width: 1200px) and (orientation: landscape) {
	.thank-you {
		align-items: center;
	}
}

.post-img {
	max-width: 500px;
}

@media screen and (min-width: 1200px) and (orientation: landscape) {
	.text-align-center {
		text-align: center;
	}
}