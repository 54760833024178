.about-me {
	padding-bottom: 30px;


}

.about-me a {
	color: var(--yellow-links-second)
}

.about-me a:hover {
	color: var(--link-hover)
}

.about-img {
	display: block;
	width: 100% !important;
}

.about-img-square {
	display: none;
}

@media (max-width: 745px) {
	.about-img {
		display: none;
	}

	.about-img-square {
		display: block;
		border-radius: 50%;
	}
}