.click-image-container {
	margin-bottom: 10px;
	margin-top: 28px;
}

.margin-sides {
	justify-content: center;
	margin: 0 10px;
}

.callout {
	padding: 10px 6px;
	max-width: 500px;
	font-family: 'Lora', serif;
	border: solid 1px white;
	text-align: center;
}