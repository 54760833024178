.post-img {
	max-width: 500px;
}

.post-img-caption {
	font-size: 12px;
}

.blog-post-intro-text {
	font-size: inherit;
}

@media screen and (min-width: 1200px) and (orientation: landscape) {
	.blog-post-intro-text {
		font-size: 1.2rem;
	}
}