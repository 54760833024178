@media screen and (min-width: 1200px) and (orientation: landscape) {
	.blog-section-title {
		font-family: 'BIZUDPMincho', serif;
		font-weight: 800;
		font-style: normal;
		letter-spacing: 0em;
		text-transform: none;
		line-height: 1.4em;
		font-size: 1.5rem;
		padding: 8px;
	}
}