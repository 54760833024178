.header-container {
	display: flex;
	justify-content: center;
	padding-top: 2.2vw;
	padding-bottom: 3.2vw;
}

.header-container a:hover {
	color: inherit;
}

.header-container-mod-padding-bottom {
	padding-bottom: calc(3.2vw + 5px);
}

.header-logo {
	height: 70px;
}

.title-container {
	display: flex;
	flex-direction: column;
}

.main-title {
	font-size: 2rem;
	color: var(--yellow-text-color);
	font-family: 'BIZUDPMincho', serif;
	line-height: 1.4em;
}

.sub-title {
	font-family: 'Satisfy', cursive;
	font-size: 18px;
	text-align: center;
}

.links-title {
	color: var(--yellow-links);
	text-align: center;
	display: flex;
	justify-content: center;
}

.header-link {
	color: var(--yellow-links);
	padding: 0 4px;
}

.title-link-wrapper {
	padding-left: 10px;
}

.title-link-wrapper img {
	height: 20px;
}

@media screen and (max-width: 745px) {
	.header-logo {
		height: 48px;
	}
}