@font-face {
    font-family: 'BIZUDPMincho';
    src: local('BIZUDPMincho'),
        url(./fonts/BIZUDPMincho-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'BIZUDPMincho';
    font-weight: 700;
    src: local('BIZUDPMincho'),
        url(./fonts/BIZUDPMincho-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Satisfy';
    src: local('Satisfy'),
        url(./fonts/Satisfy-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'SegoeUI';
    src: local('SegoeUI'), url(./fonts/SegoeUI.ttf) format('truetype');
}

@font-face {
    font-family: 'Lora';
    src: local('Lora'), url(./fonts/Lora.ttf) format('truetype');
}

@font-face {
    font-family: 'seguiemj';
    src: local('seguiemj'),
        url(./fonts/seguiemj.ttf) format('truetype');
}

:root {
    --main-bg-color: #002283;
    --main-bg-footer-color: #1642BF;
    --main-text-color: #ffffff;
    --yellow-text-color: #FFB81C;
    --yellow-links: #bb8103;
    --yellow-links-second: #FCCA0C;
    --link-hover: #0d6efd;

}

body {
    margin: 0;
    box-sizing: border-box;
    font-family: 'SegoeUI', sans-serif;

    background-color: var(--main-bg-color);
    color: var(--main-text-color);
}

a {
    color: #FCCA0C;
}

a:hover {
    color: var(--link-hover);
}