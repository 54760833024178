.main-container {
	max-width: 916px;
	margin: auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-rows: auto;
	gap: 12px;
}

.tile-container {
	height: 100%;
	width: 100%;
	text-align: center;
}

.tile-container img {
	width: 100%;
	display: block;
	height: auto;
	aspect-ratio: auto 300 / 300;
}

.tile-container a {
	color: var(--yellow-links-second);
}

.tile-container a:hover {
	color: var(--link-hover);
}

@media (max-width: 760px) {
	.main-container {
		padding: 0 0.5rem;
	}
}