.post-title-container {
	padding-left: 5%;
	padding-bottom: 5%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.post-title {
	font-family: 'BIZUDPMincho', serif;
	font-weight: 800;
	line-height: 1.4em;
	font-size: 1.5rem;
}

.post-date {
	font-family: serif;
	padding-left: 5%;
	line-height: 1.4em;
}