@media screen and (min-width: 1200px) and (orientation: landscape) {
	.blog-section-subheading {
		font-family: 'BIZ UDPMincho', serif;
		font-weight: 500;
		font-style: normal;
		letter-spacing: 0em;
		text-transform: none;
		line-height: 1.2em;
		font-size: 1rem;
		padding: 8px;
		text-align: center;
	}
}