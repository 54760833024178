.blog-post-intro-text {
	font-size: inherit;
}

@media screen and (min-width: 1200px) and (orientation: landscape) {
	.space-above {
		padding-top: 5%;
	}

	.blog-post-intro-text {
		font-size: 1.2rem;
	}
}