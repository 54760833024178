.footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: var(--main-bg-footer-color);
	color: var(--yellow-links);
	text-align: center;
	padding: 8px;
}

.footer a:hover {
	color: inherit;
}

.footer-link {
	color: var(--yellow-links);
	padding: 0 8px;
}

.footer-link img {
	height: 20px;
}

.no-underline {
	text-decoration: none;
}

@media screen and (max-width: 745px) {
	.footer-link {
		padding: 0 7px;
	}
}